<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="12"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Penjualan
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Penjualan
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <!--<v-text-field-->
                                    <!--label="Tanggal"-->
                                    <!--class="purple-input"-->
                                    <!--v-model="date"-->
                                    <!--/>-->
                                    <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted"
                                                    class="purple-input"
                                                    label="Tanggal"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <input type="hidden" v-model="customer_id"/>
                                    <v-text-field
                                            class="purple-input"
                                            readonly
                                            label="Pelanggan"
                                            v-model="customer_name"
                                    >
                                        <v-icon slot="append" color="red" size="30"
                                                @click="openModalCustomer()">mdi-magnify
                                        </v-icon>
                                        <!--<span slot="append" color="red" size="30"-->
                                        <!--@click="openModalBarang(index)">open-->
                                        <!--</span>-->
                                    </v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3">
                                    <v-select
                                            class="purple-input"
                                            v-model="status_bayar"
                                            label="Status Bayar"
                                            :items="['Belum Dibayar', 'Dibayar Sebagian', 'Lunas']"
                                    />
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="12"
                                >
                                    <v-simple-table>
                                        <thead>
                                        <tr>
                                            <th class="primary--text">
                                                No
                                            </th>
                                            <th class="primary--text">
                                                Produk
                                            </th>
                                            <th class="primary--text" width="80">
                                                Qty Unit
                                            </th>
                                            <!--<th class="primary&#45;&#45;text">-->
                                            <!--Ukuran/Unit-->
                                            <!--</th>-->
                                            <th class="primary--text">
                                                Harga
                                            </th>
                                            <th class="primary--text">
                                                Subtotal
                                            </th>
                                            <th class="primary--text">
                                                Hapus
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr v-for="(item, index) in barang" :key="index">
                                            <td>{{(index + 1)}}<input type="hidden" name="id" id="id"
                                                                      v-model="item.id"/>
                                                <!--<input type="hidden" name="date" id="date" v-model="item.date"/>-->
                                            </td>
                                            <td>
                                                <v-text-field
                                                        class="purple-input"
                                                        readonly
                                                        v-model="item.name"
                                                >
                                                    <v-icon slot="append" color="red" size="30"
                                                            @click="openModalBarang(index)">mdi-magnify
                                                    </v-icon>
                                                    <!--<span slot="append" color="red" size="30"-->
                                                    <!--@click="openModalBarang(index)">open-->
                                                    <!--</span>-->
                                                </v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                        class="purple-input text-right"
                                                        v-model="item.qty"
                                                        type="number"
                                                />
                                                <!--<vue-numeric class="purple-input"-->
                                                <!--currency-symbol-position="suffix"-->
                                                <!--separator="."-->
                                                <!--v-model="item.qty">-->

                                                <!--</vue-numeric>-->
                                            </td>
                                            <!--<td>-->
                                            <!--<v-text-field-->
                                            <!--class="purple-input"-->
                                            <!--v-model="item.weight"-->
                                            <!--:suffix="item.uom_code"-->
                                            <!--type="number"-->
                                            <!--/>-->
                                            <!--&lt;!&ndash;<vue-numeric class="purple-input"&ndash;&gt;-->
                                            <!--&lt;!&ndash;:currency="item.uom_code"&ndash;&gt;-->
                                            <!--&lt;!&ndash;currency-symbol-position="suffix"&ndash;&gt;-->
                                            <!--&lt;!&ndash;separator="."&ndash;&gt;-->
                                            <!--&lt;!&ndash;v-model="item.weight">&ndash;&gt;-->

                                            <!--&lt;!&ndash;</vue-numeric>&ndash;&gt;-->
                                            <!--</td>-->
                                            <td>
                                                <v-text-field
                                                        readonly
                                                        class="purple-input"
                                                        v-model="item.retail_prices"
                                                        prefix="Rp"
                                                />
                                                <input type="hidden" v-model="item.retail_prices" v-money="money"/>
                                                <!--<money class="purple-input form-input input-lg" v-model="item.retail_prices" v-bind="money"/>-->
                                                <!--type="number"-->
                                            </td>
                                            <td class="text-right">
                                                <v-text-field
                                                        class="purple-input"
                                                        v-model="item.subtotal"
                                                        prefix="Rp"
                                                        readonly
                                                />
                                                <!--v-model="item.subtotal"-->
                                                <!--<input type="hidden" v-model="item.subtotal" v-money="money" />-->
                                            </td>
                                            <td class="text-center">
                                                <v-icon
                                                        color="error"
                                                        class="mx-1"
                                                        @click="hapus(index)"
                                                >
                                                    mdi-close
                                                </v-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                                <v-col
                                        cols="12"
                                        class="text-center"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="tambahBarang"
                                    >
                                        <v-icon left>mdi-plus</v-icon>
                                        Tambah
                                    </v-btn>
                                </v-col>

                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="simpan"
                                    >
                                        Simpan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="modalCustomer" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Pelanggan
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalCustomer = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchCustomer"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersCustomer"
                                            :items="itemsCustomer"
                                            :search="searchCustomer"
                                    >

                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihCustomer(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihBarang(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalCustomer = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="modalBarang" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Product
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalBarang = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchBarang"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersBarang"
                                            :items="itemsBarang"
                                            :search="searchBarang"
                                    >
                                        <template v-slot:item.retail_price="{ item }">
                                            <input type="hidden" v-model.lazy="item.retail_price" v-money="money"/>
                                            {{item.retail_price}}
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihBarang(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihBarang(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalBarang = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import VueNumeric from 'vue-numeric'
    import {Money} from 'v-money'

    export default {
        name: 'FormGoodsReceipt',
        components: {
            Money
        },
        data() {
            return {
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: '',
                menu: false,
                modal: false,
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                indexPilih: 0,
                order_id: 0,
                customer_id: 0,
                status_bayar: 'Lunas',
                customer_name: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: true /* doesn't work with directive */
                },
                barang: [{
                    id: null,
                    fk_product_id: 0,
                    name: '',
                    qty: 0,
//                    weight: 0,
                    uom_code: '',
                    retail_prices: 0,
                    subtotal: 0,
//                    date: new Date().toISOString().substr(0, 10),
                }],
                deletedItems: [],
                modalCustomer: false,
                searchCustomer: '',
                headersCustomer: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Alamat',
                        filterable: true,
                        value: 'address',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Telp',
                        filterable: true,
                        value: 'telp',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsCustomer: [],
                modalBarang: false,
                searchBarang: '',
                headersBarang: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Kategori',
                        filterable: true,
                        value: 'category_name',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Harga',
                        filterable: true,
                        value: 'retail_price',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Satuan',
                        filterable: true,
                        value: 'uom_name',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsBarang: [],
                isLoadData: false,
                prices: 0,
                strprice: ''
            }
        },
        computed: {
            ...mapState(['currentUser']),
            computedDateFormatted() {
                return this.formatDate(this.date)
            },

        },
        watch: {
            barang: {
                handler: function (val, oldVal) {
//                    let t = this
//                    console.log(t.prices)
                    val.forEach(function (item, i) {
//                        t.strprice = item.retail_prices.toString()
//                        console.log(t.strprice)
//                        console.log('index '+i)
//                        t.prices = t.strprice.replace(".","")
//                        console.log(prices, prices*2)
//                        this.produk.retail_price = this.produk.retail_price.replace(/\./g,'')
                        item.subtotal = parseFloat(item.qty.toString().replace(/\./g, '')) * parseFloat(item.retail_prices.toString().replace(/\./g, ''))
//                        console.log('subtotal '+item.subtotal, 'qty '+item.qty, 'prices '+t.prices)
                    })
                },
                deep: true
            },
            date: function (val) {

                this.formatDate(val)

                this.barang.forEach(function (item, i) {
                    item.date = val
                })
            }
        },
        mounted: function () {
            this.dataBarang()
            this.dataCustomer()
            let id = this.$route.params.id
//            console.log('edit')
//            console.log(date)
            if (id !== undefined) {
                this.dataPenjualan(id)
//                this.dateFormatted = date
//                const [day, month, year] = date.split('-')
//                this.date = `${year}-${month}-${day}`

            } else {
                this.computedDateFormatted
            }

        },
        methods: {
            formatDate: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if(year.length > 3)
                    this.dateFormatted = `${day}-${month}-${year}`
                else
                    this.dateFormatted = this.date
            },
            openModalCustomer: function () {
                this.modalCustomer = true
            },
            dataCustomer: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'customers', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        this.isLoadData = true
                        if (response.data.success) {
                            this.itemsCustomer = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihCustomer: function (item) {
                console.log('pilih customer')
                console.log(item)
                this.customer_id = item.id
                this.customer_name = item.name
                this.modalCustomer = false
            },
            openModalBarang: function (i) {
                this.modalBarang = true
                this.indexPilih = i
            },
            dataBarang: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'products', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        this.isLoadData = true
                        if (response.data.success) {
                            this.itemsBarang = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihBarang: function (item) {
                console.log('pilih produk')
                console.log(item)
                this.barang[this.indexPilih].name = item.name
                this.barang[this.indexPilih].fk_product_id = item.id
                this.barang[this.indexPilih].uom_code = item.uom_code
                this.barang[this.indexPilih].retail_prices = item.retail_price
                this.modalBarang = false
            },
            dataPenjualan: function (id) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'order/detail/' + id, headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.date = response.data.data.order_date;
                            this.order_id = response.data.data.id;
//                            const [day, month, year] = this.date.split('-')
//                            this.date = `${year}-${month}-${day}`
                            this.customer_id = response.data.data.fk_customer_id
                            this.customer_name = response.data.data.customer_name
                            this.barang = response.data.data.products
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            tambahBarang: function () {
                this.barang.push({
                    id: null,
                    fk_product_id: 0,
                    name: '',
                    qty: 0,
                    weight: 0,
                    uom_code: '',
                    retail_prices: 0,
                    subtotal: 0,
                    date: this.date,
                })
            },
            hapus: function (index) {
                this.deletedItems.push(this.barang[index].order_product_id)

                if (this.barang.length > 1)
                    this.barang.splice(index, 1)
                else {
                    this.barang[index].id = null
                    this.barang[index].fk_product_id = 0
                    this.barang[index].name = ''
                    this.barang[index].qty = 0
//                    this.barang[index].weight = 0
                    this.barang[index].uom_code = ''
                    this.barang[index].retail_prices = 0
                    this.barang[index].subtotal = 0
//                    this.barang[index].date = this.date
                }


            },
            simpan: function () {
                console.log('date',this.date, 'custid',this.customer_id,this.status_bayar,this.barang.length)
                if (this.date != '' && this.customer_id != '' && this.customer_name != '' && this.status_bayar != '' && this.barang.length > 0) {
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json',
                    }
                    let data = {
                        order_products: [],
                        order: {},
                        deletedItems: this.deletedItems
                    };
                    let total_amount = 0;
                    let error = false;
                    this.barang.some(function (item, i) {
                        console.log(item)
                        if(item.fk_product_id == 0 || item.qty == 0){
                            error = true
                            return true
                        }

                        data.order_products.push({
                            id: item.order_product_id,
                            product_id: item.fk_product_id,
                            qty: item.qty,
                        })
                        total_amount += item.subtotal
                    });

                    if(error){
                        this.snackbar = true
                        this.color = 'error'
                        this.message = 'Produk dan quantity harus diisi'
                        return true;
                    }
                    data.order.id = this.order_id
                    data.order.fk_customer_id = this.customer_id
                    data.order.subtotal_amount = total_amount
                    data.order.total_amount = total_amount
                    data.order.status_bayar = this.status_bayar
                    data.order.order_time = this.date

                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'order/simpanweb', data: data, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil simpan                    '
                                this.barang = [{
                                    id: null,
                                    fk_product_id: 0,
                                    name: '',
                                    qty: 0,
//                                    weight: 0,
                                    uom_code: '',
                                    retail_prices: 0,
                                    subtotal: 0,
                                    date: '',
                                }]
                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal simpan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Tanggal, pelanggan dan status bayar harus diisi'
                }
            },
        }
    }
</script>
